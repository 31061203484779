'use client';

import Image from 'next/image';
import React from 'react';
import { DEFAULT_IMAGE_PATH } from '@web/constants/general';
import { MapPin } from '@repo/ui/components/ui/icons';
type IBuCardProps = {
  title: string;
  url?: string | undefined;
  description: string;
};

export const BusinessCard = ({
  title,
  description,
  url = DEFAULT_IMAGE_PATH,
}: IBuCardProps) => {
  return (
    <div className="hover:opacity-hover w-full space-y-3 hover:cursor-pointer">
      <div className="bg-muted  relative w-full overflow-clip rounded-2xl pt-[100%]">
        <Image
          loading="lazy"
          src={url}
          alt={url}
          fill
          sizes="(min-width: 384px)"
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
      <div>
        <p className="text-b2 font-bold">
          {title.trim() === '' ? 'Untitled' : title}
        </p>
        <p className="text-b3 text-muted-foreground icon-wrapper flex items-start">
          <MapPin size={16} className="min-w-4" /> <span>{description}</span>
        </p>
      </div>
    </div>
  );
};
